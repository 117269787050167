import { getRandomCharacter, setCharAt, timeout } from 'utils';

export type CallbackFunction = (effect: Effect) => unknown;

interface EffectProps {
  value: string;
  delay: number;
  iterationMax: number;
  iterationTmo: number;
  iterationRestTmo: number;
}

export class Effect {
  static readonly instances: { [key: string]: Effect } = {};
  private readonly value: string;
  private newValue: string;
  private readonly delay: number;
  private readonly iterationRestTmo: number;
  private readonly iterationMax: number;
  private readonly iterationTmo: number;
  private readonly listeners: CallbackFunction[];

  static init(identifier: string, props: EffectProps) {
    const effect = new Effect(props);
    this.instances[identifier] = effect;

    return effect;
  }

  static find(props: { identifier: string }) {
    return this.instances[props.identifier] || null;
  }

  constructor(props: EffectProps) {
    this.value = props.value;
    this.delay = props.delay;
    this.newValue = props.value;
    this.iterationRestTmo = props.iterationRestTmo;
    this.iterationMax = props.iterationMax;
    this.iterationTmo = props.iterationTmo;
    this.listeners = [];
  }

  private async next() {
    for (let i = 0; i < this.value.length; i++) {
      // LETTER EFFECT
      void (async () => {
        for (let j = 0; j < this.iterationMax; j++) {
          let charValue = this.value[i];

          if (charValue !== ' ' && j != this.iterationMax - 1) {
            charValue = getRandomCharacter();
          }

          this.newValue = setCharAt(this.newValue, i, charValue);

          this.listeners.map((f) => f(this));

          await timeout(this.iterationTmo);
        }
      })();
      await timeout(this.delay);
    }

    await timeout(this.iterationRestTmo);
    void this.next();
  }

  public listen(f: CallbackFunction) {
    this.listeners.push(f);
  }

  public async start() {
    return this.next();
  }

  public getValue() {
    return this.newValue;
  }
}
