import { useEffect, useState } from 'react';
import { Effect } from 'utils/Effect/Effect';
import classNames from 'classnames/bind';

import style from './DealerproductionsInEffect.module.scss';

const cx = classNames.bind(style);

const text = 'Dealer Productions';
const identifier = 'dealerproductions';

const mainEffect = Effect.init(identifier, {
  value: text,
  delay: 80,
  iterationMax: 4,
  iterationTmo: 200,
  iterationRestTmo: 5000,
});
mainEffect.start();

export const DealerproductionsInEffect = () => {
  const className = 'dealerproductions-in-effect';

  const [value, setValue] = useState(text);

  useEffect(() => {
    mainEffect.listen((effect) => {
      setValue(effect.getValue());
    });
  }, []);

  return <span className={cx(`${className}__span`)}>{value}</span>;
};
