import { useRef, useEffect, useContext } from 'react';
import { ReactSmoothScrollbarContext } from 'views/Main/Main';

import { ParallaxInterface } from './Parallax.interface';

export const Parallax = (props: ParallaxInterface) => {
  const dom = useRef<HTMLDivElement | null>(null);
  const { min, max, className, children } = props;

  const reactSmoothScrollbarContext = useContext(ReactSmoothScrollbarContext);

  useEffect(() => {
    if (dom.current) {
      const rect = dom.current.getBoundingClientRect();
      const scrollY = reactSmoothScrollbarContext.scrollbarOffset.y;
      // TODO: MIGHT NEED ATTENTION. IT USED TO BE _window
      const screenHeight = window.innerHeight;

      const center = scrollY + rect.top + rect.height / 2;
      const current = scrollY + screenHeight / 2;

      const topLimit = center - screenHeight - rect.height;
      const bottomLimit = center + screenHeight + rect.height / 2;

      const extra =
        ((max - min) * (current - topLimit)) / (bottomLimit - topLimit) + min;

      dom.current.style.transform = `translateY(${extra}px)`;
    }
  }, [dom, reactSmoothScrollbarContext]);

  return (
    <div ref={dom} className={className}>
      {children}
    </div>
  );
};
