import { useRef, useState, createContext, RefObject } from 'react';
import { MenuIcon } from 'icons/MenuIcon/MenuIcon';
import { ReactSmoothScrollbar } from 'containers/ReactSmoothScrollbar';
import { DealerproductionsInEffect } from 'components/DealerproductionsInEffect/DealerproductionsInEffect';
import { SectionContainer } from 'containers/SectionContainer/SectionContainer';
import backgroundImage1 from 'assets/images/background1.png';
import backgroundImage2 from 'assets/images/background2.png';
import backgroundImage3 from 'assets/images/background3.png';
import backgroundImage4 from 'assets/images/background4.png';
import classNames from 'classnames/bind';
import Scrollbar from 'smooth-scrollbar';
import { ArrowDirectionEnum } from 'enums/ArrowDirectionEnum';
import { scrollToRef } from 'utils';
import SmoothScrollbar from 'smooth-scrollbar';
import { useTranslation } from 'react-i18next';

import { Support } from './Support/Support';
import { Contact } from './Contact/Contact';
import { About } from './About/About';
import { Home } from './Home/Home';

import style from './Main.module.scss';

const cx = classNames.bind(style);

export const ReactSmoothScrollbarContext = createContext<{
  scrollbarInstance: Scrollbar | null;
  scrollbarOffset: {
    x: number;
    y: number;
  };
  setScrollbarInstance?: (value: SmoothScrollbar | null) => unknown;
  setScrollbarOffset?: (value: { x: number; y: number }) => unknown;
}>({
  scrollbarInstance: null,
  scrollbarOffset: { x: 0, y: 0 },
});

export const Main = () => {
  const { t } = useTranslation();

  const className = 'main';
  const homeRef = useRef<HTMLDivElement | null>(null);
  const aboutRef = useRef<HTMLDivElement | null>(null);
  const contactRef = useRef<HTMLDivElement | null>(null);
  const supportRef = useRef<HTMLDivElement | null>(null);

  const [scrollbarInstance, setScrollbarInstance] = useState<Scrollbar | null>(
    null
  );
  const [scrollbarOffset, setScrollbarOffset] = useState({ x: 0, y: 0 });
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const onNavbarToggleClick = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const onScrollbarInit = (scrollbar: Scrollbar) => {
    setScrollbarInstance(scrollbar);
    scrollbar.addListener(() => {
      setScrollbarOffset({ x: scrollbar.offset.x, y: scrollbar.offset.y });
    });
  };

  const handleNavigation = (scrollRef: RefObject<HTMLElement>) => () => {
    scrollToRef(scrollbarInstance, scrollRef);
    setIsNavbarOpen(false);
  };

  return (
    <div className={cx(`${className}__root`)}>
      {/* body start */}
      <div className={cx(`${className}__content`)}>
        {/* header start */}
        <div className={cx(`${className}__navbarWrapper`)}>
          <div className={cx(`${className}__appBar`)}>
            <div className={cx(`${className}__navBrandContainer`)}>
              <button
                className={cx(
                  `${className}__navItem`,
                  `${className}__navBrand`
                )}
                onClick={handleNavigation(homeRef)}
              >
                <DealerproductionsInEffect />
              </button>
            </div>
            <div className={cx(`${className}__navToggle`)}>
              <button
                className={cx(`${className}__iconButton`)}
                onClick={onNavbarToggleClick}
              >
                <MenuIcon />
              </button>
            </div>

            <div
              className={cx(
                { show: isNavbarOpen },
                `${className}__navbarCollapse`
              )}
            >
              <div className={cx(`${className}__navbar`)}>
                <button
                  className={cx(`${className}__navItem`)}
                  onClick={handleNavigation(aboutRef)}
                >
                  {t('navbar_about_item')}
                </button>

                <button
                  className={cx(`${className}__navItem`)}
                  onClick={handleNavigation(contactRef)}
                >
                  {t('navbar_contact_item')}
                </button>

                <button
                  className={cx(`${className}__navItem`)}
                  onClick={handleNavigation(supportRef)}
                >
                  {t('navbar_support_item')}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* header end */}

        <div className={cx(`${className}__body`)}>
          <ReactSmoothScrollbar onInit={onScrollbarInit}>
            <ReactSmoothScrollbarContext.Provider
              value={{
                scrollbarInstance,
                scrollbarOffset,
                setScrollbarInstance,
                setScrollbarOffset,
              }}
            >
              <div
                className={cx(`${className}__sectionContainerWrapper`)}
                ref={homeRef}
              >
                <SectionContainer
                  backgroundImage={backgroundImage1}
                  arrowDirection={ArrowDirectionEnum.DOWN}
                  navigateScrollToRef={aboutRef}
                >
                  <Home />
                </SectionContainer>
              </div>
              <div
                className={cx(`${className}__sectionContainerWrapper`)}
                ref={aboutRef}
              >
                <SectionContainer
                  backgroundImage={backgroundImage2}
                  arrowDirection={ArrowDirectionEnum.DOWN}
                  navigateScrollToRef={contactRef}
                >
                  <About />
                </SectionContainer>
              </div>
              <div
                className={cx(`${className}__sectionContainerWrapper`)}
                ref={contactRef}
              >
                <SectionContainer
                  backgroundImage={backgroundImage3}
                  arrowDirection={ArrowDirectionEnum.DOWN}
                  navigateScrollToRef={supportRef}
                >
                  <Contact />
                </SectionContainer>
              </div>
              <div
                className={cx(`${className}__sectionContainerWrapper`)}
                ref={supportRef}
              >
                <SectionContainer
                  backgroundImage={backgroundImage4}
                  arrowDirection={ArrowDirectionEnum.UP}
                  navigateScrollToRef={homeRef}
                >
                  <Support />
                </SectionContainer>
              </div>
            </ReactSmoothScrollbarContext.Provider>
          </ReactSmoothScrollbar>
        </div>
      </div>
      {/* body end */}
    </div>
  );
};
