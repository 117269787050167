export const EN = {
  navbar_about_item: 'About',
  navbar_contact_item: 'Contact',
  navbar_support_item: 'Support',
  about_section_header: 'About',
  about_section_description:
    'Advanced Web technology. In dept experience with Node.js and cloud formation.',
  contact_section_header: 'Contact',
  contact_section_email_header: 'Email',
  contact_section_email_content: 'dealerproductions@protonmail.com',
  contact_section_discord_header: 'Discord',
  contact_section_discord_content: 'Dealer.',
  support_section_header: 'Support',
  support_section_paypal_header: 'Paypal',
  support_section_paypal_content: 'dealerproductions@protonmail.com',
};
