import { QueryCache, QueryClient } from 'react-query';

const DEFAULT_STALE_TIME = 0;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: DEFAULT_STALE_TIME,
      retry: false,
    },
    mutations: {},
  },
  queryCache: new QueryCache(),
});
