import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { queryClient } from 'api/queryClient';
import { MousePositionContextProvider } from 'context/MousePositionContext/MousePositionContextProvider';

import { App } from './App';
import './i18n';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <MousePositionContextProvider>
        <App />
      </MousePositionContextProvider>
    </QueryClientProvider>
  </StrictMode>
);
