import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import style from './About.module.scss';

const cx = classNames.bind(style);

export const About = () => {
  const { t } = useTranslation();

  const className = 'about';

  return (
    <div>
      <div className={cx(`${className}__header`)}>
        {t('about_section_header')}
      </div>
      <div className={cx(`${className}__description`)}>
        {t('about_section_description')}
      </div>
    </div>
  );
};
