import { useRef, useEffect, useContext } from 'react';
import { MousePositionContext } from 'context/MousePositionContext/MousePositionContext';
import classNames from 'classnames/bind';

import { MouseFollowInterface } from './MouseFollow.interface';

import style from './MouseFollow.module.scss';

const cx = classNames.bind(style);

export const MouseFollow = (props: MouseFollowInterface) => {
  const className = 'mouse-follow';

  const { multiplier, className: domClassName, children } = props;

  const domWrapperRef = useRef<HTMLDivElement | null>(null);
  const domRef = useRef<HTMLDivElement | null>(null);

  const mousePositionContext = useContext(MousePositionContext);

  useEffect(() => {
    const domWrapper = domWrapperRef.current;
    const dom = domRef.current;

    if (dom && domWrapper) {
      const rectWrapper = domWrapper.getBoundingClientRect();
      const rect = dom.getBoundingClientRect();

      const objTop = rectWrapper.top;
      const objLeft = rectWrapper.left;

      const objHeight = rect.height;
      const objWidth = rect.width;

      const mouseX =
        mousePositionContext.mousePosition.x - objLeft - objWidth / 2;
      const mouseY =
        mousePositionContext.mousePosition.y - objTop - objHeight / 2;

      const ratio = objHeight / objWidth;

      let rad = Math.atan2(mouseY, mouseX);

      if (rad < 0) {
        rad = 2 * Math.PI + rad;
      }
      const deg = (rad * 180) / Math.PI;

      let x;
      let y;

      const b = (objHeight / ratio) * multiplier;
      const a = objWidth * multiplier;

      if (mouseX ** 2 / a ** 2 + mouseY ** 2 / b ** 2 <= 1) {
        x = mouseX;
        y = mouseY;
      } else {
        if ((deg >= 0 && deg < 90) || (deg > 270 && deg <= 360)) {
          x = (a * b) / Math.sqrt(b ** 2 + a ** 2 * Math.tan(rad) ** 2);
          y =
            (a * b * Math.tan(rad)) /
            Math.sqrt(b ** 2 + a ** 2 * Math.tan(rad) ** 2);
        }

        if (deg > 90 && deg < 270) {
          x = -(a * b) / Math.sqrt(b ** 2 + a ** 2 * Math.tan(rad) ** 2);
          y =
            -(a * b * Math.tan(rad)) /
            Math.sqrt(b ** 2 + a ** 2 * Math.tan(rad) ** 2);
        }
      }

      dom.style.transform = `translate(${x}px, ${y}px)`;
    }
  }, [domRef, domWrapperRef, mousePositionContext]);

  return (
    <div ref={domWrapperRef} className={cx(`${className}__domWrapper`)}>
      <div ref={domRef} className={domClassName}>
        {children}
      </div>
    </div>
  );
};
