import Scrollbar from 'smooth-scrollbar';
import { RefObject } from 'react';

export const timeout = (milliseconds: number) =>
  new Promise((resolve) => setTimeout(resolve, milliseconds));

export const getRandomCharacter = () => {
  const charset =
    'abcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`-=;\':"\\|';

  return charset.charAt(Math.floor(Math.random() * charset.length));
};

export const scrollToRef = (
  scrollbar: Scrollbar | null,
  navigateScrollToRef: RefObject<HTMLElement>
) => {
  const scrollTo = navigateScrollToRef.current;

  if (scrollbar && scrollTo) {
    const rect = scrollTo.getBoundingClientRect();
    scrollbar.scrollTo(0, rect.top + scrollbar.offset.y, 1500);
  }
};

export const setCharAt = (str: string, index: number, chr: string) => {
  if (index > str.length - 1) return str;

  return str.substring(0, index) + chr + str.substring(index + 1);
};
