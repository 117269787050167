import classNames from 'classnames/bind';
import { DealerproductionsInEffect } from 'components/DealerproductionsInEffect/DealerproductionsInEffect';
import { MouseFollow } from 'containers/MouseFollow/MouseFollow';
import { Parallax } from 'containers/Parallax/Parallax';

import style from './Home.module.scss';

const cx = classNames.bind(style);

export const Home = () => {
  const className = 'home';

  return (
    <Parallax min={-150} max={150} className={cx(`${className}__parallax`)}>
      <MouseFollow multiplier={0.1} className={cx(`${className}__mouseFollow`)}>
        <div className={cx(`${className}__wrapper`)}>
          <div className={cx(`${className}__container`)}>
            <DealerproductionsInEffect />
          </div>
        </div>
      </MouseFollow>
    </Parallax>
  );
};
