import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { QUERY_LANG_NAME } from '../utils/constant';
import { Language, Lang } from './i18n.types';
import { EN } from './translations';

const resources: Record<Language, { translation: Lang }> = {
  [Language.En]: {
    translation: EN,
  },
};

const options: InitOptions = {
  fallbackLng: Language.En,
  supportedLngs: Object.values(Language),
  resources,
  react: {
    transSupportBasicHtmlNodes: true,
  },
  detection: {
    lookupSessionStorage: 'lang',
    order: ['caseInsensitiveQueryParamsDetector', 'sessionStorage'],
    caches: ['sessionStorage'],
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: 'caseInsensitiveQueryParamsDetector',
  lookup: () => {
    const params = new URLSearchParams(window.location.search);

    return params.get(QUERY_LANG_NAME)?.toLowerCase();
  },
});

void i18n.use(languageDetector).use(initReactI18next).init(options);

export default i18n;
