import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import style from './Support.module.scss';

const cx = classNames.bind(style);

export const Support = () => {
  const { t } = useTranslation();

  const className = 'support';

  return (
    <div>
      <div className={cx(`${className}__header`)}>
        {t('support_section_header')}
      </div>

      <div className={cx(`${className}__description`)}>
        <div className={cx(`${className}__description_item`)}>
          <span className={cx(`${className}__type`)}>{t('Paypal')}: </span>
          {t('support_section_paypal_content')}
        </div>
      </div>
    </div>
  );
};
