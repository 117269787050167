import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import style from './Contact.module.scss';

const cx = classNames.bind(style);

export const Contact = () => {
  const { t } = useTranslation();

  const className = 'contact';

  return (
    <div>
      <div className={cx(`${className}__header`)}>
        {t('contact_section_header')}
      </div>

      <div className={cx(`${className}__description`)}>
        <div className={cx(`${className}__description_item`)}>
          {t('contact_section_email_header')}:{' '}
          <a
            className={cx(`${className}__link`)}
            href="mailto:dealerproductions@protonmail.com"
          >
            {t('contact_section_email_content')}
          </a>
        </div>

        <div className={cx(`${className}__description_item`)}>
          {t('contact_section_discord_header')}:{' '}
          {t('contact_section_discord_content')}
        </div>
      </div>
    </div>
  );
};
