import { useContext } from 'react';
import classNames from 'classnames/bind';
import { Parallax } from 'containers/Parallax/Parallax';
import { ExpandLessIcon } from 'icons/ExpandLessIcon/ExpandLessIcon';
import { ExpandMoreIcon } from 'icons/ExpandMoreIcon/ExpandMoreIcon';
import { ReactSmoothScrollbarContext } from 'views/Main/Main';
import { ArrowDirectionEnum } from 'enums/ArrowDirectionEnum';
import { scrollToRef } from 'utils';

import { SectionContainerInterface } from './SectionContainer.interface';

import style from './SectionContainer.module.scss';

const cx = classNames.bind(style);

export const SectionContainer = (props: SectionContainerInterface) => {
  const className = 'section-container';
  const {
    backgroundImage,
    children,
    arrowDirection,
    navigateScrollToRef,
  } = props;

  const reactSmoothScrollbarContext = useContext(ReactSmoothScrollbarContext);

  const handleArrowNavigation = () => {
    const scrollbarInstance = reactSmoothScrollbarContext.scrollbarInstance;
    scrollToRef(scrollbarInstance, navigateScrollToRef);
  };

  return (
    <>
      <div className={cx(`${className}__backgroundWrapper`)}>
        <Parallax
          min={-150}
          max={150}
          className={cx(`${className}__backgroundContainer`)}
        >
          <img
            src={backgroundImage}
            className={cx(`${className}__background`)}
            alt="background"
          />
        </Parallax>
      </div>

      <div className={cx(`${className}__shadow`)} />

      <div className={cx(`${className}__pageWrapper`)}>
        <div className={cx(`${className}__pageContainer`)}>{children}</div>
        {(() => {
          if (arrowDirection === ArrowDirectionEnum.UP) {
            return (
              <div className={cx(`${className}__arrowUpWrapper`)}>
                <button
                  className={cx(`${className}__iconButton`)}
                  onClick={handleArrowNavigation}
                >
                  <ExpandLessIcon />
                </button>
              </div>
            );
          } else {
            return (
              <div className={cx(`${className}__arrowDownWrapper`)}>
                <button
                  className={cx(`${className}__iconButton`)}
                  onClick={handleArrowNavigation}
                >
                  <ExpandMoreIcon />
                </button>
              </div>
            );
          }
        })()}
      </div>
    </>
  );
};
