import 'styles/root.scss';
import { BrowserRouter } from 'react-router-dom';
import { Main } from 'views/Main/Main';

export const App = () => {
  return (
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  );
};
