import { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { MousePositionContext } from './MousePositionContext';

interface MousePositionContextProviderInterface {
  children: ReactNode;
}

export const MousePositionContextProvider = (
  props: MousePositionContextProviderInterface
) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    document.addEventListener('mousemove', (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    });
  });

  return (
    <MousePositionContext.Provider value={{ mousePosition, setMousePosition }}>
      {props.children}
    </MousePositionContext.Provider>
  );
};
