import { createContext } from 'react';

export const MousePositionContext = createContext<{
  mousePosition: {
    x: number;
    y: number;
  };
  setMousePosition?: (value: { x: number; y: number }) => unknown;
}>({
  mousePosition: {
    x: 0,
    y: 0,
  },
});
