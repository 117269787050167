import { useEffect, useRef, FC } from 'react';
import classNames from 'classnames/bind';
import SmoothScrollbar from 'smooth-scrollbar';

import { ReactSmoothScrollbarProps } from './ReactSmoothScrollbar.props';

import style from './ReactSmoothScrollbar.module.scss';
const cx = classNames.bind(style);

export const ReactSmoothScrollbar: FC<ReactSmoothScrollbarProps> = ({
  children,
  onInit,
}) => {
  const className = 'react-smooth-scrollbar';
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef?.current) {
      const scrollbar = SmoothScrollbar.init(containerRef?.current);
      onInit?.(scrollbar);
    }
  }, [containerRef]);

  return (
    <div ref={containerRef} className={cx(`${className}__scrollbar`)}>
      <div>{children}</div>
    </div>
  );
};
